.quiz-button {
  border-radius: 50px;
  border: none;

  margin: 0.5em;
  padding: 0.25em 1em;

  width: calc(33.333% - 1em);

  background-color: #F0B250;
  color: white;
  font-size: 0.9em;
  overflow-x: hidden;
}

.quiz-buttons-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 18em;
  overflow-y: auto;
}

.training-box {
  border: 1px solid #2f353a;
  margin: 10px;
  border-radius: 0.25rem;
  width: calc(50% - 20px);
  padding: 20px;
}
