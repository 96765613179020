.filterContainer {
	display: flex;
	flex-wrap: wrap;
	button {
		margin: 0px 15px 0px 15px;
	}
}

.periodSelect {
	width: 170px;
}

.nonChangeableValue {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	border-color: #e4e7ea;
	border-width: 1px 1px 1px 0;
    border-style: solid;
}

.cardContainer {
	display: flex;
	margin-bottom: 10px;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.statCard {
	flex-shrink: 1;
	flex-basis: 30%;
	border: 1px solid #c9c9c9;
	border-radius: 8px;
	overflow: hidden;
	margin: 10px;
}

.statCardTitle {
	margin: 0px;
	background-color: #6EB882;
	width: 100%;
	text-align: center;
	h2 {
		padding: 15px;
	}
}

.statCardOutliersTitle {
	background-color: #DEB887;
}

.statCardInfo {
	padding: 10px;
	> div {
		margin-bottom: 15px;
	}
	table {
		th {
			border-bottom: 1px solid black;
		}
		th:first-child {
			border-right: 1px solid black;
		}
		td:first-child {
			border-right: 1px solid black;
		}
		th, td {
			padding: 5px 5px 0;
		}
	}
}

.statCardPending {
    display: flex;
    flex-direction: row;
}

.pendingDVTooltip {
    overflow-y: auto;
    max-height: 500px;
    max-width: 1000px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.customersDVTitle {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 10px;
	h2 {
		margin-bottom: 0px;
	}
	.MuiTextField-root {
		background-color: #fff;
	}
}

.accuracyAssessmentTitle {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	button {
		margin: 0px 0px 10px 20px;
		background-color: #fff;
	}
}

.accuracyTablesLayout {
	display: flex;
	flex-direction: row;
	width: 100%;
	div:nth-of-type(1) {
		margin-right: 10px;
		flex: 1.5;
	}
	div:nth-of-type(2) {
		margin-left: 10px;
		flex: 1;
	}
}

.noResults {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	font-size: 15px;
}

.imagesDataValidity {
    display: flex;
    flex-direction: column;
    position: relative;

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-bottom: 10px;

        * {
            margin: 5px;
        }

    }
}

.subtypeGroup {
    border-bottom: 2px solid black;
    padding: 15px;
    margin: 10px 0 20px;
    h2 {
        font-weight: bold;

        span {
            display: flex;
            flex-direction: row;
            gap: 5px;
            
            .green{
                color: #008000;
            }

            .red{
                color: #FF0000;
            }
        }
    }
    .seeMoreButton {
        text-align: center;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 600;
        color: #c76f00;
    }
}

.taskList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(36vh + 20px));;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    justify-content: center;

    margin-top: 10px;
    margin-bottom: 10px;

    .labelEdit {
        position: sticky;
    }

    .task {
        display: flex;
        flex-direction: column;
        background-color: rgb(234, 236, 236);
        padding: 10px;
        border-radius: 5px;

        .taskImage {
            margin-bottom: 8px;
            position: relative;

            .lazyLoaderTask {
                width: 36vh;
                height: 26.9vh;
                background: white url('./assets/svgs/H3e9z0b.gif') center no-repeat;
                background-size: 36vh 26.9vh;
                display: inline-flex;
                border: none;
                border-radius: 3px;
            }

            .indicator {
                position: absolute;
                top: 5px;
                right: 5px;
                left: none;

                font-weight: 700;
                color: rgb(78, 95, 98);

                padding: 2px 6px;

                background-color: #fafafa;
                border-radius: 5px;

                transition: opacity .25s ease, filter .25s ease;

                cursor: default;
            }

            .indicatorEdited {
                position: absolute;
                right: 5px;
                left: none;
                bottom: 5px;

                font-weight: 700;
                color: rgb(78, 95, 98);

                padding: 2px 6px;

                background-color: #fafafa;
                border-radius: 5px;

                transition: opacity .25s ease, filter .25s ease;

                cursor: default;
            }

            .indicator:hover {
                filter: opacity(0);
            }

            .indicatorEdited:hover {
                filter: opacity(0);
            }
        }

        h4 {
            font-size: 1rem;
            line-height: 1.2rem;
            margin: 0px 2px;
        }


        p {
            margin: 0px 2px;
            font-size: 0.95rem;
        }

        .MuiDivider-root {
            margin: 5px 0px;
        }

        .labels {
            margin-bottom: 8px;
        }

        .radioButtons {
            display: block;
            margin-top: auto;

            background-color: #fafafa;
            border-radius: 5px;
            padding: 3px;

            user-select: none;

            .radio {
                cursor: pointer;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
}
